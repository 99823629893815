var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { deleteDoc, doc, getDoc, onSnapshot, setDoc } from "firebase/firestore";
import { isEqual } from "lodash";
import { COLLECTIONS } from "../constants/collections";
import { firebase } from "../services";
import { sentry } from "../utils";
export var OnboardingStepsEnum;
(function (OnboardingStepsEnum) {
    OnboardingStepsEnum["CREATE_CARD"] = "CREATE_CARD";
    OnboardingStepsEnum["CREATE_LINK"] = "CREATE_LINK";
    OnboardingStepsEnum["INSTALL_EXTENSION"] = "INSTALL_EXTENSION";
    OnboardingStepsEnum["CREATE_CARD_WITH_EXTENSION"] = "CREATE_CARD_WITH_EXTENSION";
    OnboardingStepsEnum["IMPORT_BOOKMARKS"] = "IMPORT_BOOKMARKS";
    OnboardingStepsEnum["CREATE_CARD_IN_APP_SEARCH"] = "CREATE_CARD_IN_APP_SEARCH";
    OnboardingStepsEnum["CREATE_CARD_WITH_SHARE_TARGET"] = "CREATE_CARD_WITH_SHARE_TARGET";
    OnboardingStepsEnum["CREATE_ACCOUNT"] = "CREATE_ACCOUNT";
    OnboardingStepsEnum["VERIFY_ACCOUNT"] = "VERIFY_ACCOUNT";
})(OnboardingStepsEnum || (OnboardingStepsEnum = {}));
export var SummaryLengthEnum;
(function (SummaryLengthEnum) {
    SummaryLengthEnum["detailed"] = "detailed";
    SummaryLengthEnum["concise"] = "concise";
})(SummaryLengthEnum || (SummaryLengthEnum = {}));
export class UserRepository {
    constructor(storage, options) {
        this.user = null;
        this.setUserInStorage = (user) => __awaiter(this, void 0, void 0, function* () {
            const storageUser = yield this.storage.get();
            if (isEqual(user, storageUser))
                return;
            this.storage.set(user);
        });
        this.getUser = (uid, options) => __awaiter(this, void 0, void 0, function* () {
            var _a;
            try {
                if (this.user && !(options === null || options === void 0 ? void 0 : options.forceFetch) && !((_a = this.options) === null || _a === void 0 ? void 0 : _a.alwaysRefetch))
                    return this.user;
                const userRef = doc(firebase.firestore, COLLECTIONS.USERS, uid);
                const snapshot = yield getDoc(userRef);
                const user = (Object.assign(Object.assign({}, (this.user || {})), snapshot.data()) || null);
                this.user = user;
                this.setUserInStorage(user);
                return user;
            }
            catch (e) {
                const err = e;
                sentry.captureException(err);
                return yield this.storage.get();
            }
        });
        this.upsertUser = (uid, payload) => __awaiter(this, void 0, void 0, function* () {
            if (!uid)
                throw new Error("User id must be provided");
            const user = ((yield this.getUser(uid)) || {});
            const newUser = Object.assign(Object.assign({}, user), payload);
            if (isEqual(newUser, user))
                return;
            newUser.updatedAt = Date.now();
            this.user = newUser;
            const userRef = doc(firebase.firestore, COLLECTIONS.USERS, uid);
            yield setDoc(userRef, newUser, { merge: true });
            yield this.storage.set(newUser);
        });
        this.removeUser = () => {
            this.user = null;
            this.storage.remove();
        };
        this.observeUser = (uid, callback) => {
            const dbVersionRef = doc(firebase.firestore, COLLECTIONS.USERS, uid);
            const unsubscribe = onSnapshot(dbVersionRef, { includeMetadataChanges: true }, (snapshot) => __awaiter(this, void 0, void 0, function* () {
                var _a;
                const data = snapshot.data();
                if ((data === null || data === void 0 ? void 0 : data.updatedAt) &&
                    ((_a = this.user) === null || _a === void 0 ? void 0 : _a.updatedAt) &&
                    data.updatedAt < this.user.updatedAt) {
                    return;
                }
                const newUser = Object.assign(Object.assign({}, (this.user || {})), data);
                const isUserSame = isEqual(newUser, this.user);
                if (isUserSame)
                    return;
                this.user = newUser;
                callback(this.user);
            }));
            return unsubscribe;
        };
        this.deleteUser = (uid) => __awaiter(this, void 0, void 0, function* () {
            const userRef = doc(firebase.firestore, COLLECTIONS.USERS, uid);
            yield deleteDoc(userRef);
        });
        this.storage = storage;
        this.options = options || {};
    }
}
