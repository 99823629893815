import { Add, ArrowDropDown } from "@mui/icons-material"
import {
    Button,
    ButtonGroup,
    ClickAwayListener,
    Grow,
    MenuItem,
    MenuList,
    Paper,
    Popper,
    SxProps,
    Theme,
} from "@mui/material"
import { useDatabase } from "@nozbe/watermelondb/react"
import {
    OnboardingStepsEnum,
    WIKIPEDIA,
    checkIsUrl,
    createEmptyItem,
    libraryRepository,
    posthogService,
    sentry,
    tutorials,
} from "@recall/common"
import { PdfUploadModal } from "components/shared/modals/PdfUploadModal"
import { RERENDER_TAGS_EVENT } from "components/shared/tags/hooks/useGroupedTags"
import {
    COMPLETE_TUTORIAL_EVENT,
    CREATE_BLANK_CARD_CLICKED,
    SEARCH_SHORTCUT_USED,
} from "constants/events"
import { SAVE_LIB_SUMMARY_PATH, SHARE_TARGET_PATH } from "constants/routes"
import { useOpenItem } from "hooks/items/useOpenItem"
import { useIsMobile } from "hooks/useIsMobile"
import { useIsNative } from "hooks/useIsNative"
import { Result } from "hooks/useSearchItems"
import isHotkey from "is-hotkey"
import { uniq } from "lodash"
import { memo, useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router"
import { captureEventService } from "services/captureEventService"
import { itemAPI } from "storage/api/items/Item"
import { SET_IMPORT_STATE, SET_LOADING } from "storage/redux/app/actionTypes"
import { importEnum } from "storage/redux/app/types"
import { RootState } from "storage/redux/rootReducer"
import { SET_SEARCH_TERM, TUTORIAL_COMPLETE } from "storage/redux/user/actionTypes"
import { setOnboarding } from "storage/redux/user/actions"
import { ItemModel } from "storage/watermelon/models"
import { itemRepository } from "storage/watermelon/repository"
import { useOpenOnboarding } from "../../Onboarding/hooks/useOpenOnboarding"
import { SearchDialog } from "./SearchDialog"

export const OPEN_SEARCH_CUSTOM_EVENT = "open-search-custom-event"

export const AddButton = memo(() => {
    const db = useDatabase()
    const history = useHistory()
    const dispatch = useDispatch()
    const [open, setOpen] = useState(false)
    const [isPdfUploadOpen, setIsPdfUploadOpen] = useState(false)
    const [isDropdownOpen, setIsDropdownOpen] = useState(false)
    const searchTerm = useSelector((state: RootState) => state.user.searchTerm)
    const isMobile = useIsMobile()
    const isNative = useIsNative()
    const onboarding = useSelector((state: RootState) => state.user.onboarding)
    const addButtonRef = useRef<HTMLDivElement>(null)

    const { openItem } = useOpenItem()

    const { closeOnboarding } = useOpenOnboarding()

    useEffect(() => {
        const handleListenShortcut = (e: KeyboardEvent) => {
            if (isHotkey("cmd+k", e) || isHotkey("ctrl+k", e)) {
                posthogService.captureEvent(SEARCH_SHORTCUT_USED)
                handleOpenSearch()
            }
        }

        document.addEventListener(OPEN_SEARCH_CUSTOM_EVENT, handleOpenSearch)
        document.addEventListener("keydown", handleListenShortcut)

        return () => {
            document.removeEventListener("keydown", handleListenShortcut)
            document.removeEventListener(OPEN_SEARCH_CUSTOM_EVENT, handleOpenSearch)
        }
        // eslint-disable-next-line
    }, [])

    const setSearchTerm = (searchTerm: string) => {
        dispatch({ type: SET_SEARCH_TERM, payload: searchTerm })
    }

    const handleSelectResult = async (result: Result) => {
        handleClose()

        if (result === null) return null
        const item = await fetchItem(result)
        if (!item) return null
        const tagsCount = await item.tags.count
        if (tagsCount) {
            document.dispatchEvent(new CustomEvent(RERENDER_TAGS_EVENT))
        }

        closeOnboarding()
        openItem(item)
    }

    const finishCreateCardInSearchOnboarding = () => {
        if (
            onboarding?.finished &&
            !onboarding?.finished?.includes(OnboardingStepsEnum.CREATE_CARD_IN_APP_SEARCH)
        )
            dispatch(
                setOnboarding({
                    ...onboarding,
                    finished: uniq([
                        ...onboarding.finished,
                        OnboardingStepsEnum.CREATE_CARD_IN_APP_SEARCH,
                    ]),
                })
            )
    }

    const generateSummary = async (name: string) => {
        const libraryItem = await libraryRepository.getLibraryItemByUrlOrVideoId(name)
        if (libraryItem) {
            history.push(`${SAVE_LIB_SUMMARY_PATH}?id=${libraryItem.id}`)
        } else {
            history.push(`${SHARE_TARGET_PATH}?url=${name}`)
        }
        finishCreateCardInSearchOnboarding()
        setSearchTerm("")
    }

    const fetchItem = async (result: Result): Promise<ItemModel | null> => {
        if (result.isGenerateSummaryOption) {
            await generateSummary(result.name)
            return
        }

        dispatch({ type: SET_LOADING, payload: true })
        try {
            if (result?.isCreateOption) {
                return await itemRepository.createFull(db, result)
            } else if (result.isSaved) {
                return await itemRepository.get(db, result.id)
            } else {
                const item = await itemRepository.getBySources(db, result.sources, true)
                if (item) return item

                const wikipediaSource = result.sources.find((source) => source.name === WIKIPEDIA)
                if (wikipediaSource) {
                    const item = await itemAPI.getAndSaveWikipedia(
                        db,
                        wikipediaSource.identifier,
                        result.language
                    )
                    if (item) await item.setIsExpanded(true)
                    return item
                }
            }
        } catch (e) {
            sentry.captureException(e)
        } finally {
            dispatch({ type: SET_LOADING, payload: false })
            finishCreateCardInSearchOnboarding()
        }

        return null
    }

    const completeTutorial = () => {
        dispatch({ type: TUTORIAL_COMPLETE, payload: tutorials.ITEMS_INTRO })
        captureEventService.capture(COMPLETE_TUTORIAL_EVENT, {
            tutorialName: tutorials.ITEMS_INTRO,
        })
    }

    const handleClose = () => {
        setOpen(false)
        setSearchTerm("")
    }

    const handleOpenSearch = () => {
        completeTutorial()
        setOpen(true)
    }

    const handleSubmit = async () => {
        if (checkIsUrl(searchTerm)) {
            await generateSummary(searchTerm)
        }
    }

    const handleToggleDropdown = async () => {
        setIsDropdownOpen((prev) => !prev)
    }

    const handleCloseDropdown = (event: Event) => {
        if (addButtonRef.current && addButtonRef.current.contains(event.target as HTMLElement)) {
            return
        }

        setIsDropdownOpen(false)
    }

    const handleCreateBlank = async (e) => {
        e.stopPropagation()
        posthogService.captureEvent(CREATE_BLANK_CARD_CLICKED)
        const item = await itemRepository.createFull(db, createEmptyItem())
        openItem(item)
    }

    const handleOpenImportBookmarks = () => {
        dispatch({ type: SET_IMPORT_STATE, payload: importEnum.OPENED })
    }

    const handleOpenUploadPdf = () => {
        setIsPdfUploadOpen(true)
    }

    return (
        <>
            <ButtonGroup
                ref={addButtonRef}
                variant="contained"
                aria-label="Button group with a nested menu"
                size={isMobile ? "large" : "small"}
            >
                <Button
                    onClick={handleOpenSearch}
                    sx={styles.addButton}
                    color="primary"
                    variant="contained"
                    startIcon={<Add />}
                >
                    New
                </Button>
                <Button
                    color="primary"
                    sx={{ px: 0, minWidth: `${isMobile ? "40px" : "10px"} !important` }}
                    onClick={handleToggleDropdown}
                >
                    <ArrowDropDown />
                </Button>
            </ButtonGroup>
            <Popper
                sx={{
                    zIndex: 1,
                }}
                placement="bottom-end"
                open={isDropdownOpen}
                anchorEl={addButtonRef.current}
                role={undefined}
                transition
                disablePortal
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin:
                                placement === "bottom" ? "center top" : "center bottom",
                        }}
                    >
                        <Paper sx={{ py: 0 }}>
                            <ClickAwayListener onClickAway={handleCloseDropdown}>
                                <MenuList id="split-button-menu" sx={{ py: 0 }} autoFocusItem>
                                    <MenuItem sx={{ px: 1 }} onClick={handleOpenSearch}>
                                        From URL
                                    </MenuItem>
                                    <MenuItem sx={{ px: 1 }} onClick={handleCreateBlank}>
                                        Empty Card
                                    </MenuItem>
                                    <MenuItem sx={{ px: 1 }} onClick={handleOpenUploadPdf}>
                                        Upload PDF
                                    </MenuItem>
                                    {!isMobile && !isNative && (
                                        <MenuItem
                                            onClick={handleOpenImportBookmarks}
                                            sx={{ px: 1 }}
                                        >
                                            Import Bookmarks
                                        </MenuItem>
                                    )}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
            {isPdfUploadOpen && (
                <PdfUploadModal
                    closeModal={() => {
                        setIsPdfUploadOpen(false)
                    }}
                />
            )}
            <SearchDialog
                open={open}
                onClose={handleClose}
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
                handleSubmit={handleSubmit}
                handleSelectResult={handleSelectResult}
            />
        </>
    )
})

const styles: Record<string, SxProps<Theme>> = {
    addButton: {
        fontSize: 16,
        p: 0,
        pl: 1,
        pr: 1.5,
        m: 0,
    },
}
