import { Database } from "@nozbe/watermelondb"
import {
    getMatchedReferences,
    getTextReferences,
    HttpClientError,
    Item,
    ItemApi,
    ItemPartial,
    LinkApi,
    ROOT_TYPE_NAME,
    sentry,
    SummaryLengthEnum,
    toItem,
    toItemPartial,
    TypeApi,
    WEBSITE,
    WIKIPEDIA,
} from "@recall/common"

import { TagApi } from "@recall/common/dist/esm/api/types/TagApi"
import { deserializeMd } from "components/ItemPage/components/editor/parsers/markdownToSlate"
import { orderBy } from "lodash"
import { summariesApi } from "services/api"
import { EditorBlockData } from "services/editorData/EditorBlockData"
import { ImageBlockData } from "services/editorData/ImageBlockData"
import {
    connectionRepository,
    itemRepository,
    ROOT_TAG_ID,
    tagRepository,
} from "storage/watermelon/repository"
import { v4 as uuid } from "uuid"
import { ConnectionModel, ItemModel, TagModel } from "../../watermelon/models"

const searchWikipedia = async (query: string, language = "en"): Promise<ItemPartial[]> => {
    try {
        const itemApis = await summariesApi.findWikipediaSummary(query, language)
        if (!itemApis) return []
        return itemApis.map((item) => toItemPartial(item)).filter(Boolean)
    } catch (e) {
        sentry.captureException(e)
        return []
    }
}

const getAndSaveWikipedia = async (db: Database, slug: string, language = "en") => {
    try {
        const itemApi = await summariesApi.summarizeWikipediaPage(slug, language)
        if (!itemApi) return null
        const itemModel = await saveItemApi(db, itemApi, false)
        return itemModel
    } catch (e) {
        sentry.captureException(e)
        return null
    }
}

const getAndSaveScraped = async (
    db: Database,
    url: string,
    language = "en",
    summaryLength: SummaryLengthEnum
) => {
    try {
        const { data, cost } = await summariesApi.summarizePage(url, summaryLength, { language })
        if (!data) return { item: null, cost: 0 }
        const item = await saveItemApi(db, data, true)
        return { item, cost }
    } catch (err) {
        const error = err as HttpClientError
        return { item: null, cost: 0, error: error.details }
    }
}

const getAndSavePdf = async (
    db: Database,
    name: string,
    summaryLength: SummaryLengthEnum,
    formData: FormData,
    language = "en"
) => {
    try {
        const { data, cost } = await summariesApi.summarizePdfFile(
            null,
            name,
            summaryLength,
            formData,
            {
                language,
                isSaveInBackgroundAllowed: false,
            }
        )
        if (!data) return { item: null, cost: 0 }
        const item = await saveItemApi(db, data, true)
        return { item, cost }
    } catch (err) {
        const error = err as HttpClientError
        return { item: null, cost: 0, error: error.details }
    }
}

const addItemTagsByType = async (db: Database, type: TypeApi | null, item: ItemModel) => {
    return await db.write(async (action) => {
        if (!type || type.name === ROOT_TYPE_NAME) return

        let parentId = ROOT_TAG_ID
        for (const { display } of type?.genealogy?.reverse()) {
            if (display === ROOT_TYPE_NAME) continue

            const existingTag = await tagRepository.getTagByNameAndParentId(db, display, parentId)

            if (existingTag) {
                parentId = existingTag.id
            } else {
                // eslint-disable-next-line no-loop-func
                const tag = await action.callWriter(() =>
                    tagRepository.create({ db, name: display, parentId, isSaved: item.isSaved })
                )
                parentId = tag.id
            }
        }

        const tag: TagModel = await action.callWriter(() =>
            tagRepository.create({ db, name: type.display, item, parentId })
        )

        return tag
    })
}

const addItemTags = async (db: Database, tags: TagApi[] | null, item: ItemModel) => {
    return await db.write(async (action) => {
        if (!tags.length) return

        for (const tag of tags) {
            const existingTag = await tagRepository.getTagByName(db, tag.name)

            if (existingTag) {
                await action.callWriter(() => tagRepository.attach(db, existingTag, item))
                continue
            }

            await action.callWriter(() => tagRepository.create({ db, name: tag.name, item }))
        }
    })
}

const getEditorBlocks = (item: Item, linksConnections: LinkConnection[]) => {
    if (!item.markdown) return item.editorBlocks

    const title = `# ${item.name}\n`
    const imageUrl = item.image
    let image = imageUrl ? `![Image](${imageUrl})\n` : ""

    const markdown = insertMarkdownLinks(item.markdown, linksConnections)

    const editorBlocks = deserializeMd(title + image + markdown)

    return editorBlocks.map((editorBlock) =>
        editorBlock.id ? editorBlock : { ...editorBlock, id: uuid() }
    )
}

const replaceSpecialCharacters = (markdown: string) => {
    return markdown.replaceAll(`\\`, "\\\\")
}

const insertMarkdownLinks = (markdown: string, links: LinkConnection[]) => {
    const placeholders: Record<string, string> = {}
    const orderedLinks = orderBy(links, ({ link }) => link.item.name.length, "desc")
    let markdownLines = markdown.split("\n")

    for (const { link, connection } of orderedLinks) {
        const textReferences = getTextReferences(link.mention_texts, link.item.name)
        const matchedReferences = getMatchedReferences(markdownLines, textReferences)

        for (const matchedReference of matchedReferences) {
            placeholders[`__${matchedReference}__`] = `[${matchedReference}](${connection.id})`
        }
    }

    let updatedMarkdown = markdownLines.join("\n")

    for (const [placeholder, replacement] of Object.entries(placeholders)) {
        updatedMarkdown = updatedMarkdown.split(placeholder).join(replacement)
    }

    return replaceSpecialCharacters(updatedMarkdown)
}

interface LinkConnection {
    link: LinkApi
    connection: ConnectionModel
}

interface SaveItemOptions {
    id?: string
    isExpanded?: boolean
}

const saveLinks = async ({
    db,
    itemApi,
    isSaved,
    editorBlocks,
    itemModel,
}: {
    db: Database
    itemApi: ItemApi
    isSaved: boolean
    editorBlocks: any[]
    itemModel: ItemModel
}) => {
    return await db.write(async (writer) => {
        const linksConnections: LinkConnection[] = []
        for (let link of itemApi.links) {
            let linkedItem = await itemRepository.getBySources(db, link.item.sources)

            if (!linkedItem) {
                linkedItem = await writer.callWriter(() => saveItemApi(db, link.item, isSaved))
            }

            const connection: ConnectionModel = await writer.callWriter(() =>
                connectionRepository.create(
                    db,
                    {
                        fromId: itemModel.id,
                        toId: linkedItem.id,
                        property: link.property,
                    },
                    isSaved
                )
            )
            linksConnections.push({ link, connection })

            if (itemApi.markdown) continue

            for (let editorBlock of editorBlocks) {
                for (let child of editorBlock.children) {
                    if (child.children) {
                        for (let grandChild of child.children) {
                            if (
                                grandChild.connectionId?.toLowerCase() === link.slug?.toLowerCase()
                            ) {
                                grandChild.connectionId = connection.id
                            }
                        }
                    }
                }
            }
        }
        return linksConnections
    })
}

const saveItemApi = async (
    db: Database,
    itemApi: ItemApi,
    isSaved?: boolean,
    options?: SaveItemOptions
): Promise<ItemModel> => {
    return await db.write<ItemModel>(async (writer) => {
        const item = toItem({
            ...itemApi,
        })

        if (options?.id) {
            item.id = options.id
        }
        item.image = ""
        if (item?.images?.[0]) {
            const imageUrl = ImageBlockData.getUrl320(item.images[0])
            item.image = imageUrl
        }

        item.isExpanded = options?.isExpanded || item.isExpanded
        const itemModel = await writer.callWriter(() => itemRepository.create(db, item, isSaved))

        await writer.callWriter(() => addItemTagsByType(db, itemApi.type, itemModel))
        await writer.callWriter(() => addItemTags(db, itemApi.tags, itemModel))

        let editorBlocks = item.markdown ? [] : item.editorBlocks

        let linksConnections: LinkConnection[] = []

        if ("links" in itemApi)
            linksConnections = await writer.callWriter(() =>
                saveLinks({
                    db,
                    itemModel,
                    editorBlocks,
                    isSaved,
                    itemApi,
                })
            )

        editorBlocks = item.markdown ? getEditorBlocks(item, linksConnections) : editorBlocks
        await writer.callWriter(() => itemModel.addEditorBlocks(editorBlocks, isSaved))
        await writer.callWriter(() => itemModel.addSources(item.sources, isSaved))
        await itemModel.update((record) => {
            record.description = EditorBlockData.getTextWithoutHeadings(editorBlocks)
        })

        return itemModel
    })
}

const updateItemLanguage = async (
    db: Database,
    itemModel: ItemModel,
    itemApi: ItemApi,
    language: string
): Promise<ItemModel> => {
    return await db.write<ItemModel>(async (writer) => {
        const item = toItem(itemApi)
        let editorBlocks = item.markdown ? [] : item.editorBlocks
        if (item?.images?.[0]) {
            const imageUrl = ImageBlockData.getUrl320(item.images[0])
            try {
                await fetch(imageUrl, { mode: "no-cors" })
                item.image = imageUrl
            } catch {}
        }
        if (item?.images?.[0]) item.image = ImageBlockData.getUrl320(item.images[0])

        const text = EditorBlockData.getText(item.editorBlocks)
        item.description = text.length > 60 ? text.slice(0, 60).trim() + "..." : text

        const existingLinks = await itemModel.links
        for (const link of existingLinks) {
            const item = await link.to.fetch()
            await writer.callWriter(() => link.delete())
            const backlinksCount = await item.mentions.count
            if (backlinksCount === 0) await writer.callWriter(() => item.delete())
        }

        let linksConnections: LinkConnection[]
        if ("links" in itemApi)
            linksConnections = await writer.callWriter(() =>
                saveLinks({
                    db,
                    itemModel,
                    editorBlocks,
                    isSaved: true,
                    itemApi,
                })
            )

        editorBlocks = item.markdown ? getEditorBlocks(item, linksConnections) : editorBlocks

        await writer.callWriter(() => itemModel.updateName(item.name))
        await writer.callWriter(() => itemModel.updateDescription(item.description))
        await writer.callWriter(() => itemModel.updateImage(item.image))

        const blocks = await itemModel.editorBlocks.fetch()
        for (const block of blocks) await writer.callWriter(() => block.delete())
        const orders = await itemModel.editorOrders.fetch()
        for (const order of orders) await writer.callWriter(() => order.delete())

        await writer.callWriter(() => itemModel.addEditorBlocks(editorBlocks, true))
        const wikipediaSource = await itemModel.getSource(WIKIPEDIA)
        const websiteSource = await itemModel.getSource(WEBSITE)
        if (wikipediaSource) await writer.callWriter(() => wikipediaSource.delete())
        if (websiteSource) await writer.callWriter(() => websiteSource.delete())

        const existingSources = await itemModel.sources.fetch()
        const missingSources = item.sources.filter((source) =>
            existingSources.every(
                (existingSource) =>
                    existingSource.name !== source.name &&
                    existingSource.identifier !== source.identifier
            )
        )
        await writer.callWriter(() => itemModel.addSources(missingSources, true))
        await writer.callWriter(() => itemModel.setIsExpanded(true))
        await writer.callWriter(() => itemModel.updateLanguage(language))

        return itemModel
    })
}

export const itemAPI = {
    searchWikipedia,
    getAndSaveWikipedia,
    getAndSaveScraped,
    getAndSavePdf,
    saveItemApi,
    updateItemLanguage,
}
