import { Paper, PopperProps, useTheme } from "@mui/material"
import { useIsMobile } from "hooks/useIsMobile"
import React from "react"
import MyPopper from "./MyPopper"

export const getHeightFromBottom = (element) => {
    const BUFFER = 0
    const domRect = element.getBoundingClientRect()
    const spaceBelow = window.innerHeight - domRect.bottom - BUFFER
    return spaceBelow
}

const DropdownPopper: React.FC<React.PropsWithChildren<PopperProps>> = (props) => {
    const isMobile = useIsMobile()
    const { children, ...rest } = props
    const theme = useTheme()

    let heightFromBottom = 280
    let width = 400

    const anchorEl = props.anchorEl as HTMLElement

    if (props.open && anchorEl) {
        if (!isMobile) {
            heightFromBottom = getHeightFromBottom(anchorEl) - 20
            width = anchorEl.offsetWidth
        }
    }

    return (
        <MyPopper
            sx={{
                zIndex: theme.zIndex.modal,
                [theme.breakpoints.up("sm")]: {
                    width: `${width}px`,
                    minWidth: "400px",
                },
                [theme.breakpoints.down("sm")]: {
                    width: "100%",
                    padding: "0 15px",
                },
            }}
            modifiers={[{ name: "flip", enabled: false }]}
            {...rest}
        >
            <Paper
                elevation={3}
                sx={{
                    overflow: "auto",
                    maxHeight: `${heightFromBottom}px`,
                }}
            >
                {children}
            </Paper>
        </MyPopper>
    )
}

export default DropdownPopper
