var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { buildPath, buildUrl } from "../utils/httpClient/common";
const YOUTUBE_TRANSCRIPT_SUMMARY_PATH = "/scraper/youtube/";
const YOUTUBE_SECTIONS_SUMMARY_PATH = "/scraper/youtube/sections/";
const PAGE_SUMMARY_PATH = "/scraper/";
const PAGE_HTML_SUMMARY_PATH = "/scraper/encoded-html/";
const PDF_HTML_SUMMARY_PATH = "/scraper/pdf/encoded-html/";
const PDF_FILE_SUMMARY_PATH = "/scraper/pdf/upload/";
const WIKI_SUMMARY_PATH = "/items/get";
const WIKI_SUMMARY_SEARCH_PATH = "/items/search";
const SUMMARY_PREVIEW_PATH = "/search-preview/";
const EXPAND_SUMMARY_URL_PATH = "/expand_url/";
const INVALIDATE_SUMMARY_CACHE_PATH = "/cache/invalidate-html/";
export class SummariesApi {
    constructor(httpClient) {
        this.httpClient = httpClient;
    }
    getCost(response) {
        return Number(response.headers.get("x-response-effort")) || null;
    }
    getIsCardSaved(response) {
        return Boolean(response.headers.get("Is-Card-Saved-In-Background"));
    }
    getResponse(response) {
        return __awaiter(this, void 0, void 0, function* () {
            const data = yield response.json();
            const cost = this.getCost(response);
            const isCardSavedInBackground = this.getIsCardSaved(response);
            return { data, cost, isCardSavedInBackground };
        });
    }
    getHeaders(options) {
        const headers = { "Content-Type": "application/json" };
        if (options.language)
            headers["Accept-Language"] = options.language === "auto" ? "en" : options.language;
        if (options.isSaveInBackgroundAllowed)
            headers["Allow-Save-In-Background"] = "true";
        if (options.contentType)
            headers["Content-Type"] = options.contentType;
        return headers;
    }
    summarizeYT(payload, options) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.httpClient.post(buildPath(YOUTUBE_TRANSCRIPT_SUMMARY_PATH, payload.url), JSON.stringify(payload), this.getHeaders(options));
            return yield this.getResponse(response);
        });
    }
    summarizeYTWithSections(payload, options) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.httpClient.post(buildPath(YOUTUBE_SECTIONS_SUMMARY_PATH, payload.url), JSON.stringify(payload), this.getHeaders(options));
            return yield this.getResponse(response);
        });
    }
    summarizePage(url, summaryLength, options = {}) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.httpClient.get(buildUrl(PAGE_SUMMARY_PATH, { url, summaryLength }), this.getHeaders(options));
            return yield this.getResponse(response);
        });
    }
    summarizePageHtml(url, summaryLength, encodedHtml, options = {}) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.httpClient.post(buildPath(PAGE_HTML_SUMMARY_PATH, url), JSON.stringify({ url, encoded_html: encodedHtml, summaryLength }), this.getHeaders(options));
            return yield this.getResponse(response);
        });
    }
    summarizePdfPageHtml(url, summaryLength, encodedHtml, options = {}) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.httpClient.post(buildPath(PDF_HTML_SUMMARY_PATH, url), JSON.stringify({ url, encoded_html: encodedHtml, summaryLength }), this.getHeaders(options));
            return yield this.getResponse(response);
        });
    }
    summarizePdfFile(url, name, summaryLength, formData, options = {}) {
        return __awaiter(this, void 0, void 0, function* () {
            const headers = this.getHeaders(Object.assign(Object.assign({}, options), { language: options.language || "en" }));
            delete headers["Content-Type"];
            const response = yield this.httpClient.post(buildUrl(PDF_FILE_SUMMARY_PATH, { url, name, summaryLength }), formData, headers);
            return yield this.getResponse(response);
        });
    }
    summarizeWikipediaPage(slug, language = "en") {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.httpClient.get(buildUrl(WIKI_SUMMARY_PATH, { query: slug, language }));
            return yield response.json();
        });
    }
    findWikipediaSummary(query, language = "en") {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.httpClient.get(buildUrl(WIKI_SUMMARY_SEARCH_PATH, { query, language }));
            return yield response.json();
        });
    }
    getSummaryPreview(url) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.httpClient.get(buildUrl(SUMMARY_PREVIEW_PATH, { url }));
            return yield response.json();
        });
    }
    expandSummaryUrl(url) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.httpClient.get(buildUrl(EXPAND_SUMMARY_URL_PATH, { url }));
            const data = yield response.json();
            return null || (data === null || data === void 0 ? void 0 : data.url);
        });
    }
    invalidateSummaryCache(url, summaryLength, encodedHtml, options = {}) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.httpClient.post(buildPath(INVALIDATE_SUMMARY_CACHE_PATH, url), JSON.stringify({ url, summaryLength, encoded_html: encodedHtml }), this.getHeaders(options));
        });
    }
}
