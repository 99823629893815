// @ts-nocheck
import { tutorials } from "@recall/common"
import { seenOptions } from "hooks/useIsSeen"
import { MigrationManifest } from "redux-persist"
import { storageService } from "services/storageService"
import { extensionStateEnum, importEnum, themeModeEnum } from "./app/types"
import { RootState } from "./rootReducer"

const appMigrations: Record<number, (state: RootState) => RootState> = {
    0: (state) => {
        return {
            ...state,
            user: {
                ...state.user,
                tutorialsCompleted: {},
            },
        }
    },
    1: (state) => {
        return {
            ...state,
            drawer: {
                ...state.drawer,
                typeSection: {
                    ...state.drawer.typeSection,
                    expanded: {},
                },
            },
        }
    },
    2: (state) => {
        return {
            ...state,
            user: {
                ...state.user,
                lastDisplayedInstallPrompt: 0,
                isAppInstalled: false,
            },
        }
    },
    3: (state) => {
        return {
            ...state,
            user: {
                ...state.user,
                searchTerm: "",
            },
        }
    },
    4: (state) => {
        return {
            ...state,
            user: {
                ...state.user,
                isAppMobile: false,
            },
        }
    },
    5: (state) => {
        return {
            ...state,
            app: {
                ...state.app,
                loading: false,
                themeMode: themeModeEnum.LIGHT,
            },
        }
    },
    6: (state) => {
        return {
            ...state,
            editor: {
                ...state.editor,
                searchReferenceOpen: false,
            },
        }
    },
    7: (state) => {
        return {
            ...state,
            app: {
                ...state.app,
                bookmarksImportState: importEnum.CLOSED,
            },
        }
    },
    8: (state) => {
        return {
            ...state,
            user: {
                ...state.user,
                tutorial: {
                    completed: {
                        [tutorials.ITEMS_INTRO]:
                            state.user?.tutorialsCompleted?.["itemsIntro"] || false,
                        [tutorials.ITEM_INTRO]:
                            state.user?.tutorialsCompleted?.["itemsIntro"] || false,
                        [tutorials.EXPAND_INTRO]:
                            state.user?.tutorialsCompleted?.["expandNote"] || false,
                    },
                    active: "none",
                },
                onboarding: {
                    finished: [],
                    skipped: [],
                    isOpen: false,
                },
            },
        }
    },
    9: (state) => {
        return {
            ...state,
            drawer: {
                ...state.drawer,
                selectedTagIds: [],
            },
        }
    },
    10: (state) => {
        return {
            ...state,
            app: {
                ...state.app,
                appLoading: false,
                extensionState: extensionStateEnum.NOT_LOGGED_IN,
            },
        }
    },
    11: (state) => {
        return {
            ...state,
            app: {
                ...state.app,
                isUpgradePlanModalOpened: false,
                isCostUsageExceededModalOpen: false,
            },
            user: {
                ...state.user,
                isPremiumUser: false,
            },
        }
    },
    12: (state) => {
        return {
            ...state,
            app: {
                ...state.app,
                appLoading: true,
            },
        }
    },
    13: (state) => {
        return {
            ...state,
            user: {
                ...state.user,
                isNewUser: false,
            },
        }
    },
    14: (state) => {
        const { isNewUser, ...user } = state.user

        return {
            ...state,
            user,
        }
    },
    15: (state) => {
        return {
            ...state,
            items: { ...state.items, orderBy: "updatedAt" },
        }
    },
    16: (state) => {
        return {
            ...state,
            user: {
                ...state.user,
                surveys: [],
            },
        }
    },
    17: (state) => {
        return {
            ...state,
            user: {
                ...state.user,
                surveys: state.user?.surveys,
            },
        }
    },
    18: (state) => {
        return {
            ...state,
            user: {
                ...state.user,
                dbVersion: null,
                modals: null,
            },
        }
    },
    19: (state) => {
        return {
            ...state,
            app: {
                ...state.app,
                visibleItems: 25,
                scrollPosition: 0,
            },
        }
    },
    20: (state) => {
        return {
            ...state,
            app: {
                ...state.app,
                userLoading: true,
            },
        }
    },
    21: (state) => {
        return {
            ...state,
            user: {
                ...state.user,
                menu: {
                    expandedTags: [],
                },
            },
        }
    },
    22: (state) => {
        return {
            ...state,
            user: {
                ...state.user,
                menu: {
                    ...state.user.menu,
                    isGetRecallHidden: false,
                },
            },
        }
    },
    23: (state) => {
        return {
            ...state,
            app: {
                ...state.app,
                isNative: false,
                platform: "web",
            },
        }
    },
    24: (state) => {
        return {
            ...state,
            items: {
                ...state.items,
                openIds: state.items.openIds.map((id) => ({ id })),
            },
        }
    },
    25: (state) => {
        return {
            ...state,
            user: {
                ...state.user,
                language: "auto",
                searchLanguage: "en",
            },
            app: { ...state.app, questionsLoadingItemIds: [] },
        }
    },
    26: (state) => {
        return {
            ...state,
            user: {
                ...state.user,
                sharedCards: [],
            },
        }
    },
    27: (state) => {
        return {
            ...state,
            user: {
                ...state.user,
                isReviewEmailEnabled: true,
            },
        }
    },
    28: (state) => {
        const lastSeen = storageService.getItem(seenOptions.ONBOARDING_BANNER)
        let isOnboardingBannerVisible = !Boolean(lastSeen)

        return {
            ...state,
            user: {
                ...state.user,
                isOnboardingBannerVisible,
            },
        }
    },
    29: (state) => {
        return {
            ...state,
            items: {
                ...state.items,
                direction: "desc",
            },
        }
    },
    30: (state) => {
        return {
            ...state,
            user: {
                ...state.user,
                defaultLength: SummaryLengthEnum.detailed,
                usage: 0,
            },
        }
    },
}

export const migrations = appMigrations as MigrationManifest
