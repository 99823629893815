import { Tooltip, Typography, TypographyProps } from "@mui/material"
import React from "react"
import { truncateText } from "../../../utils/Strings"

interface Props extends Omit<TypographyProps, "children"> {
    text: string
    maxLength: number
}

const TypographyTruncated: React.FC<Props> = ({ text, maxLength, ...rest }) => {
    const { truncatedText, isTruncated } = React.useMemo(
        () => truncateText(text, maxLength),
        [text, maxLength]
    )

    const truncatedTypography = <Typography {...rest}>{truncatedText}</Typography>

    if (!isTruncated) {
        return truncatedTypography
    }

    return (
        <Tooltip title={text} disableTouchListener>
            {truncatedTypography}
        </Tooltip>
    )
}

export default TypographyTruncated
