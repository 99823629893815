import { alpha, createTheme } from "@mui/material/styles";
export const CONTENT_SCRIPT_Z_INDEX = 9999999999;
const lightPalette = {
    mode: "light",
    primary: {
        main: "#4f54b6",
    },
    secondary: {
        main: "#F2B807",
    },
    error: {
        dark: "#EE2D1B",
        main: "#F15244",
        light: "#F47367",
    },
    success: {
        dark: "#15880F",
        main: "#259c1f",
        light: "#4fa44b",
    },
    background: {
        paper: "#FFFFFF",
        default: "#F8F8F9",
        layout: "#eef0f2",
    },
    action: {
        active: "rgba(47, 56, 63, 0.80)",
        disabled: "rgba(47, 56, 63, 0.26)",
        selected: "rgba(47, 56, 63, 0.14)",
        disabledBackground: "rgba(47, 56, 63, 0.12)",
        hover: "rgba(47, 56, 63, 0.04)",
    },
    text: {
        secondary: "rgba(47, 56, 63, 0.84)",
    },
};
// https://material.io/design/color/the-color-system.html#tools-for-picking-colors
const darkPalette = {
    mode: "dark",
    primary: {
        main: "#4b4594",
    },
    secondary: {
        main: "#F2B807",
    },
    error: {
        main: alpha("#d36e65", 0.8),
    },
    success: {
        main: "#6bac65",
    },
    background: {
        paper: "#25203A",
        default: "#07011f",
        layout: "#07011f",
    },
    action: {
        active: "rgba(255, 255, 255, 0.6)",
        disabled: "rgba(255, 255, 255, 0.3)",
        selected: "rgba(255, 255, 255, 0.16)",
        disabledBackground: "rgba(255, 255, 255, 0.12)",
        hover: "rgba(255, 255, 255, 0.08)",
    },
    text: {
        primary: "rgba(255, 255, 255)",
        secondary: "rgba(255, 255, 255, 0.9)",
    },
};
const getTheme = (mode, customContainer = null, options = {}) => {
    const isForExtension = customContainer !== null;
    const isDarkMode = mode === "dark";
    const invertedBackground = isDarkMode ? lightPalette.background : darkPalette.background;
    let theme = createTheme({
        palette: isDarkMode ? darkPalette : lightPalette,
        shape: {
            borderRadius: 8,
        },
        typography: Object.assign({ fontFamily: ["Montserrat", "sans-serif"].join(","), secondaryFontFamily: ["Roboto", "Helvetica", "sans-serif", "Arial"].join(","), button: {
                textTransform: "none",
            }, 
            // @ts-ignore
            pxToRem: isForExtension ? (px) => `${px}px` : undefined }, options.typography),
    });
    // TODO - is this needed?
    // theme = responsiveFontSizes(theme)
    theme.borders = {
        paper: `1px solid ${theme.palette.action.disabled}`,
    };
    theme.components = {
        MuiMenu: {
            styleOverrides: {
                list: {
                    backgroundColor: theme.palette.background.layout,
                },
                paper: {
                    border: theme.borders.paper,
                },
            },
        },
        MuiButton: {
            defaultProps: {
                disableElevation: true,
            },
            styleOverrides: {
                containedInherit: {
                    color: theme.palette.text.secondary,
                    backgroundColor: theme.palette.action.disabledBackground,
                    boxShadow: "none",
                    "&:hover": {
                        color: theme.palette.text.primary,
                        backgroundColor: theme.palette.action.disabled,
                        boxShadow: "none",
                    },
                },
            },
        },
        MuiSkeleton: {
            styleOverrides: {
                root: {
                    backgroundColor: theme.palette.action.disabledBackground,
                    borderRadius: theme.shape.borderRadius,
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    color: theme.palette.text.primary,
                    borderColor: theme.palette.action.disabled,
                    "&.MuiOutlinedInput-notchedOutline": {
                        borderColor: theme.palette.action.disabled,
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: theme.palette.action.disabled,
                        borderWidth: 1,
                    },
                },
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    color: theme.palette.text.primary,
                    "&.Mui-focused": {
                        color: theme.palette.text.primary,
                    },
                },
            },
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    backgroundImage: "none",
                },
            },
        },
        MuiTooltip: {
            defaultProps: {
                arrow: true,
                enterTouchDelay: 200,
                leaveTouchDelay: 100,
                enterDelay: 500,
                enterNextDelay: 500,
            },
            styleOverrides: {
                tooltip: {
                    borderRadius: theme.spacing(1),
                    color: theme.palette.background.default,
                    backgroundColor: invertedBackground === null || invertedBackground === void 0 ? void 0 : invertedBackground.default,
                },
                arrow: {
                    color: invertedBackground === null || invertedBackground === void 0 ? void 0 : invertedBackground.default,
                },
            },
        },
        MuiCssBaseline: {
            styleOverrides: {
                "*::-webkit-scrollbar": {
                    width: 8,
                },
                "*::-webkit-scrollbar-track": {
                    backgroundColor: theme.palette.background.layout,
                    borderRadius: theme.shape.borderRadius,
                },
                "*::-webkit-scrollbar-thumb": {
                    background: alpha(theme.palette.grey[500], 0.3),
                    borderRadius: theme.shape.borderRadius,
                    "&:hover": {
                        background: alpha(theme.palette.grey[500], 0.6),
                    },
                },
                "::selection": {
                    backgroundColor: alpha("#B4D5FE", 0.7),
                },
            },
        },
        MuiChip: {
            styleOverrides: {
                outlined: {
                    borderColor: theme.palette.action.disabled,
                },
            },
        },
        MuiToolbar: {
            styleOverrides: {
                root: {
                    height: 50,
                    minHeight: 50,
                    "@media (min-width:600px)": {
                        height: 40,
                        minHeight: 40,
                    },
                    "@media (min-width:0px) and (orientation: landscape)": {
                        minHeight: 50,
                    },
                    "@media (min-width:600px) and (orientation: landscape)": {
                        minHeight: 40,
                    },
                },
            },
        },
    };
    if (isForExtension) {
        const componentsToUpdate = {
            MuiPopover: {
                defaultProps: {
                    container: customContainer,
                },
            },
            MuiPopper: {
                defaultProps: {
                    container: customContainer,
                },
            },
            MuiModal: {
                defaultProps: {
                    container: customContainer,
                },
            },
        };
        theme.components = Object.assign(Object.assign({}, theme.components), componentsToUpdate);
        theme.zIndex.tooltip += CONTENT_SCRIPT_Z_INDEX;
        theme.zIndex.modal += CONTENT_SCRIPT_Z_INDEX;
    }
    return theme;
};
export { getTheme };
