import { User } from "@recall/common"
import { getCompletedTutorials } from "hooks/auth/useInit"
import { isEmpty, isEqual } from "lodash"
import { useCallback } from "react"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "storage/redux/rootReducer"
import { UPDATE_USER } from "storage/redux/user/actionTypes"

export const useUpdateUser = () => {
    const dispatch = useDispatch()

    const surveys = useSelector((state: RootState) => state.user.surveys)
    const modals = useSelector((state: RootState) => state.user.modals)
    const language = useSelector((state: RootState) => state.user.language)
    const searchLanguage = useSelector((state: RootState) => state.user.searchLanguage)
    const completedTutorials = useSelector((state: RootState) => state.user.tutorial.completed)
    const onboarding = useSelector((state: RootState) => state.user.onboarding)
    const expandedTags = useSelector((state: RootState) => state.user.menu?.expandedTags)
    const isGetRecallHidden = useSelector((state: RootState) => state.user.menu?.isGetRecallHidden)
    const sharedCards = useSelector((state: RootState) => state.user.sharedCards)
    const isReviewEmailEnabled = useSelector((state: RootState) => state.user.isReviewEmailEnabled)
    const isOnboardingBannerVisible = useSelector(
        (state: RootState) => state.user.isOnboardingBannerVisible
    )
    const active = useSelector((state: RootState) => state.user.tutorial.active)
    const defaultLength = useSelector((state: RootState) => state.user.defaultLength)
    const usage = useSelector((state: RootState) => state.user.usage)

    const areValuesEqual = (value1, value2) => {
        if (isEmpty(value1) && isEmpty(value2)) return true

        return isEqual(value1, value2)
    }
    const updateUser = useCallback(
        (user: User) => {
            if (!user) return

            const completed = getCompletedTutorials(user)

            if (
                areValuesEqual(surveys, user?.surveys) &&
                areValuesEqual(modals, user?.modals) &&
                areValuesEqual(completed, completedTutorials) &&
                areValuesEqual(user?.onboarding?.finished, onboarding.finished) &&
                areValuesEqual(user?.onboarding?.skipped, onboarding.skipped) &&
                areValuesEqual(user?.menu?.expandedTags, expandedTags) &&
                user?.menu?.isGetRecallHidden === isGetRecallHidden &&
                areValuesEqual(user?.summaryOptions?.language, language) &&
                areValuesEqual(user?.summaryOptions?.searchLanguage, searchLanguage) &&
                areValuesEqual(user?.sharedCards, sharedCards) &&
                user?.isReviewEmailEnabled === isReviewEmailEnabled &&
                user?.isOnboardingBannerVisible === isOnboardingBannerVisible &&
                user?.summaryOptions?.defaultLength === defaultLength &&
                user?.usage?.extension === usage
            )
                return

            const finished = [...(user?.onboarding?.finished || [])]
            const skipped = [...(user?.onboarding?.skipped || [])]

            dispatch({
                type: UPDATE_USER,
                payload: {
                    surveys: user?.surveys,
                    modals: user?.modals,
                    tutorial: { completed: completed || completedTutorials, active },
                    onboarding: { finished, skipped },
                    menu: {
                        expandedTags: user?.menu?.expandedTags,
                        isGetRecallHidden: user?.menu?.isGetRecallHidden || false,
                    },
                    language: user?.summaryOptions?.language || language,
                    searchLanguage: user?.summaryOptions?.searchLanguage || searchLanguage,
                    defaultLength: user?.summaryOptions?.defaultLength || defaultLength,
                    sharedCards: user?.sharedCards || sharedCards,
                    isReviewEmailEnabled:
                        user?.isReviewEmailEnabled === undefined
                            ? isReviewEmailEnabled
                            : user?.isReviewEmailEnabled,
                    isOnboardingBannerVisible:
                        user?.isOnboardingBannerVisible === undefined
                            ? isOnboardingBannerVisible
                            : user.isOnboardingBannerVisible,
                    usage: user?.usage?.extension || usage,
                },
            })
        },
        // eslint-disable-next-line
        [surveys, modals, completedTutorials, onboarding, expandedTags, isReviewEmailEnabled]
    )

    return updateUser
}
