import { Box, Fade, Popper, PopperProps, useTheme } from "@mui/material"

const MyPopper: React.FC<React.PropsWithChildren<PopperProps>> = (props) => {
    const { children, ...rest } = props

    const theme = useTheme()

    return (
        <Popper transition style={{ zIndex: theme.zIndex.modal + 2 }} {...rest}>
            {({ TransitionProps }) => (
                <Fade {...TransitionProps} timeout={200}>
                    <Box>{children}</Box>
                </Fade>
            )}
        </Popper>
    )
}

export default MyPopper
