import { jsx as _jsx } from "react/jsx-runtime";
import { Box, Grid, Typography, alpha } from "@mui/material";
import { useEffect, useRef } from "react";
import Showdown from "showdown";
import { formatYoutubeTimestamp, sentry } from "../../utils";
const converter = new Showdown.Converter({
    disableForced4SpacesIndentedSublists: true,
    smartIndentationFix: true,
});
converter.addExtension({
    type: "output",
    regex: /href=\"https:\/\/www\.youtube\.com\/watch\?v=.+?t=(\d+)s\"/g,
    replace: 'href="#" data-timestamp="$1" class="youtube-timestamp"',
}, "timestampLinks");
export const MarkdownRenderer = ({ card }) => {
    const html = converter.makeHtml((card === null || card === void 0 ? void 0 : card.markdown) || "");
    const contentRef = useRef(null);
    useEffect(() => {
        if (contentRef.current) {
            contentRef.current
                .querySelectorAll("a[data-timestamp]")
                .forEach((anchor) => {
                anchor.addEventListener("click", handleTimestampClick);
                if (!anchor.textContent)
                    return;
                const formattedTimestamp = formatYoutubeTimestamp(anchor.textContent);
                anchor.textContent = formattedTimestamp;
            });
        }
        return () => {
            if (contentRef.current) {
                contentRef.current
                    .querySelectorAll("a[data-timestamp]")
                    .forEach((anchor) => {
                    anchor.removeEventListener("click", handleTimestampClick);
                });
            }
        };
    }, [html]);
    const handleTimestampClick = (e) => {
        e.preventDefault();
        const target = e.currentTarget;
        const seconds = target.getAttribute("data-timestamp");
        if (seconds)
            seekTo(parseInt(seconds, 10));
    };
    const seekTo = (seconds) => {
        const video = document.querySelector("video");
        if (video) {
            video.currentTime = seconds;
            if (video.paused)
                video.play();
        }
        else {
            sentry.captureMessage("Video not found", { url: card.sources });
        }
    };
    return html ? (_jsx(Grid, Object.assign({ item: true, xs: 12 }, { children: _jsx(Box, { ref: contentRef, sx: Object.assign({}, styles.html), dangerouslySetInnerHTML: { __html: html } }) }))) : (_jsx(Grid, Object.assign({ item: true, xs: 12 }, { children: _jsx(Typography, Object.assign({ variant: "body2", component: "p", mb: 1, whiteSpace: "pre-wrap" }, { children: card.description })) })));
};
const styles = {
    html: {
        padding: 0,
        margin: 0,
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: 1.5,
        fontFamily: (theme) => theme.typography.fontFamily,
        "& h1": {
            fontSize: "24px",
            fontWeight: 500,
            mx: 0,
            mt: 3,
            mb: 1.5,
        },
        "& h2": {
            fontSize: "20px",
            fontWeight: 500,
            mx: 0,
            mt: 3,
            mb: 1,
        },
        "& ul": {
            paddingInlineStart: "16px",
            marginBlockStart: "8px",
        },
        "& p": {
            marginBlockEnd: "8px",
        },
        "& li p": {
            margin: 0,
        },
        "& li": {
            mb: 0.5,
        },
        "& a": {
            color: (theme) => theme.palette.primary.main,
        },
        "& a.youtube-timestamp": {
            color: (theme) => alpha(theme.palette.text.primary, 0.5),
            fontStyle: "italic",
            textDecoration: "none",
            "&:hover": {
                textDecoration: "underline",
                color: "#3366ccee",
            },
        },
    },
};
