import { PlateElement, PlateElementProps } from "@udecode/plate-common"
import React from "react"
import { Box } from "@mui/material"

export const BlockquoteElement = React.forwardRef<
    React.ElementRef<typeof PlateElement>,
    PlateElementProps
>(({ className, children, ...props }, ref) => {
    return (
        <PlateElement asChild ref={ref} {...props}>
            <Box
                component="blockquote"
                sx={{
                    borderLeft: (theme) => `4px solid ${theme.palette.primary.main}`,
                    margin: "1.5em 10px",
                    padding: "0.5em 10px",
                    fontStyle: "italic",
                }}
            >
                {children}
            </Box>
        </PlateElement>
    )
})
BlockquoteElement.displayName = "BlockquoteElement"
