const PLANETS_ASSET = {
    name: "Planets",
    url: "https://storage.googleapis.com/recall-public/static_assets/images/planet.svg",
};
const LOGO_FULL_DARK_ASSET = {
    name: "Recall Logo Full Dark",
    url: "https://storage.googleapis.com/recall-public/static_assets/images/logo_full_dark.png",
};
const LOGO_FULL_LIGHT_ASSET = {
    name: "Recall Logo Full Dark",
    url: "https://storage.googleapis.com/recall-public/static_assets/images/logo_full_light_2.svg",
};
const LOGO_LIGHT_ASSET = {
    name: "Recall Logo Light",
    url: "https://storage.googleapis.com/recall-public/static_assets/images/logo_light.svg",
};
const STARS_ASSET = {
    name: "Stars",
    url: "https://storage.googleapis.com/recall-public/static_assets/images/stars.svg",
};
const PODCAST_ICON = {
    name: "Podcast Icon",
    url: "https://storage.googleapis.com/recall-public/static_assets/icons/podcast.png",
};
const ACADEMIC_ICON = {
    name: "Academic Icon",
    url: "https://storage.googleapis.com/recall-public/static_assets/icons/academic.png",
};
const WIKIPEDIA_ICON = {
    name: "Wikipedia Icon",
    url: "https://storage.googleapis.com/recall-public/static_assets/icons/wikipedia.png",
};
const RECIPE_ICON = {
    name: "Recipe Icon",
    url: "https://storage.googleapis.com/recall-public/static_assets/icons/recipe.png",
};
const NEWS_ICON = {
    name: "News Icon",
    url: "https://storage.googleapis.com/recall-public/static_assets/icons/news.png",
};
const BLOG_ICON = {
    name: "News Icon",
    url: "https://storage.googleapis.com/recall-public/static_assets/icons/blog.png",
};
const VIDEO_ICON = {
    name: "Video Icon",
    url: "https://storage.googleapis.com/recall-public/static_assets/icons/video.png",
};
const RECALL_PLUS_LIGHT = {
    name: "Recall Plus Light",
    url: "https://storage.googleapis.com/recall-public/static_assets/images/recall_plus_light.png",
};
const RECALL_PLUS_DARK = {
    name: "Recall Plus Dark",
    url: "https://storage.googleapis.com/recall-public/static_assets/images/recall_plus_dark.png",
};
const RECALL_REVEIW_TUTORIAL_1 = {
    name: "",
    url: "https://storage.googleapis.com/recall-public/static_assets/video/recall_review_1.mp4",
};
const RECALL_REVEIW_TUTORIAL_2 = {
    name: "",
    url: "https://storage.googleapis.com/recall-public/static_assets/video/recall_review_2.mp4",
};
const INSTALL_BROWSER_EXTENSION_1 = {
    name: "",
    url: "https://storage.googleapis.com/recall-public/static_assets/video/install_browser_extension_2024.mp4",
};
const INSTALL_BROWSER_EXTENSION_2 = {
    name: "",
    url: "https://storage.googleapis.com/recall-public/static_assets/video/install_browser_extension_2024_2.mp4",
};
const THANK_YOU_FOR_INSTALLING_EXTENSION = {
    name: "Thank you for installing extension",
    url: "https://storage.googleapis.com/recall-public/static_assets/video/install_browser_extension.mp4",
};
const PIN_THE_EXTENSION = {
    name: "How to pin the extension",
    url: "https://storage.googleapis.com/recall-public/static_assets/video/pin_browser_extension_2024.mp4",
};
const RECALL_LITE_LOGO = {
    name: "Recall lite logo",
    url: "https://storage.googleapis.com/recall-public/static_assets/images/lite_logo.png",
};
const RECALL_PLUS_LOGO = {
    name: "Recall plus logo",
    url: "https://storage.googleapis.com/recall-public/static_assets/images/plus_logo.png",
};
const RECALL_BUSINESS_LOGO = {
    name: "Recall plus logo",
    url: "https://storage.googleapis.com/recall-public/static_assets/images/business_logo.png",
};
const CREATE_SUMMARY_MOBILE = {
    name: "How to create summary on mobile",
    url: "https://storage.googleapis.com/recall-public/static_assets/video/mobile_share_target_demo.mp4",
};
export const assets = {
    CREATE_SUMMARY_MOBILE,
    PIN_THE_EXTENSION,
    RECALL_PLUS_LIGHT,
    RECALL_PLUS_DARK,
    PLANETS_ASSET,
    LOGO_FULL_DARK_ASSET,
    LOGO_FULL_LIGHT_ASSET,
    LOGO_LIGHT_ASSET,
    STARS_ASSET,
    PODCAST_ICON,
    ACADEMIC_ICON,
    WIKIPEDIA_ICON,
    RECIPE_ICON,
    NEWS_ICON,
    BLOG_ICON,
    VIDEO_ICON,
    RECALL_REVEIW_TUTORIAL_1,
    RECALL_REVEIW_TUTORIAL_2,
    INSTALL_BROWSER_EXTENSION_1,
    INSTALL_BROWSER_EXTENSION_2,
    THANK_YOU_FOR_INSTALLING_EXTENSION,
    RECALL_LITE_LOGO,
    RECALL_PLUS_LOGO,
    RECALL_BUSINESS_LOGO,
};
