import { MAX_DESCRIPTION_LENGTH } from "../constants"

export const slugify = (value: string) => {
    return value.replace(/ /g, "_").toLowerCase()
}

export const splitStringOnIndex = (str: string, index: number) => {
    const leftValue = str.substring(0, index)
    const rightValue = str.substring(index)

    return [leftValue, rightValue]
}

export const truncateText = (text: string, length = MAX_DESCRIPTION_LENGTH) => {
    if (text.length <= length) {
        return { truncatedText: text, isTruncated: false }
    }

    let truncatedText = text.slice(0, length)
    const lastSpaceIndex = truncatedText.lastIndexOf(" ")

    if (lastSpaceIndex !== -1) {
        truncatedText = truncatedText.slice(0, lastSpaceIndex)
    }

    truncatedText = truncatedText.replace(/[,;:.!?]\s*$/, "")

    return { truncatedText: `${truncatedText.trim()}...`, isTruncated: true }
}

export const count = (pin: string, haystack: string) => {
    return (haystack.match(new RegExp(pin, "g")) || []).length
}
