import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined"
import { Box, IconButton, SxProps, Theme, ThemeProvider, alpha } from "@mui/material"
import { RECALL_WEBSITE_URL, getTheme } from "@recall/common"
import LogoDark from "assets/svg/logo_full_dark.svg"
import { useIsNative } from "hooks/useIsNative"
import React from "react"
import { useHistory } from "react-router"

interface Props {
    showCloseButton?: boolean
    isContained?: boolean
}

const DarkLayoutComponent: React.FC<React.PropsWithChildren<Props>> = ({
    children,
    showCloseButton = false,
    isContained = false,
}) => {
    const history = useHistory()
    const isNative = useIsNative()

    const handleBack = () => {
        history.goBack()
    }

    const handleLogoClick = () => {
        if (isNative) return

        window.location.href = RECALL_WEBSITE_URL
    }

    return (
        <ThemeProvider theme={getTheme("dark")}>
            <Box sx={{ color: alpha("#FFF", 0.9) }}>
                <Box sx={styles.imageContainer} onClick={handleLogoClick}>
                    <Box
                        component="img"
                        alt="recall logo"
                        height={{ xs: 35, md: 45 }}
                        src={LogoDark}
                    />
                </Box>
                {showCloseButton && (
                    <IconButton sx={styles.close} onClick={handleBack} size="large" color="default">
                        <CloseOutlinedIcon fontSize="inherit" />
                    </IconButton>
                )}

                <Box sx={styles.background}>
                    <Box sx={styles.body}>
                        {isContained ? <Box sx={styles.container}>{children}</Box> : children}
                    </Box>
                </Box>
            </Box>
        </ThemeProvider>
    )
}

const theme = getTheme("dark")

export const HEADER_HEIGHT = "80px"

const styles: Record<string, SxProps<Theme>> = {
    header: { backgroundColor: "#161226", height: HEADER_HEIGHT },
    imageContainer: { p: 2, position: "absolute", top: 0, left: 0, cursor: "pointer" },
    background: {
        backgroundColor: (theme) => theme.palette.background.default,
        backgroundRepeat: { sm: "no-repeat, no-repeat" },
        backgroundSize: { sm: "40%, 40%" },
        backgroundPosition: { sm: "right top, left" },
        mt: { xs: 2, md: 0 },
    },
    body: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        minHeight: "100vh",
        pt: "40px",
    },
    container: {
        [theme.breakpoints.down("sm")]: {
            borderRadius: 0,
            border: "none",
            minHeight: `calc(100vh - ${HEADER_HEIGHT})`,
            background: "none",
            backdropFilter: "none",
            my: 0,
        },
        borderRadius: 1,
        my: 5,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        backgroundBlendMode: "overlay, normal",
        backdropFilter: "blur(2px)",
        background: (theme) => alpha(theme.palette.background.paper, 0.6),
    },
    close: {
        position: "absolute",
        top: { xs: 8, md: 12 },
        right: { xs: 8, md: 12 },
    },
}

export const DarkLayout = React.memo(DarkLayoutComponent)
