import { FC } from "react"
import TypographyTruncated from "../../misc/TypographyTruncated"
import { SxProps, Theme } from "@mui/material"

interface Props {
    description: string
}

export const DescriptionComp: FC<Props> = ({ description }) => {
    if (!description) return null

    return (
        <TypographyTruncated
            sx={styles.root}
            variant="body2"
            color="textSecondary"
            text={description}
            maxLength={100}
        />
    )
}

const styles: Record<string, SxProps<Theme>> = {
    root: {
        paddingBottom: (theme) => theme.spacing(0.5),
        fontFamily: (theme) => theme.typography.secondaryFontFamily,
        fontWeight: 400,
        overflowWrap: "anywhere",
        fontSize: "0.75rem",
    },
}
