import { alpha, Box } from "@mui/material"
import { PlateElement, PlateElementProps } from "@udecode/plate-common"
import { useOpenInNewTab } from "hooks/useOpenInNewTab"
import { FC } from "react"
import { MyYouTubeTimestampElement } from "../types"
import { formatYoutubeTimestamp } from "@recall/common"

export const YouTubeTimestampElement: FC<PlateElementProps<MyYouTubeTimestampElement[]>> = ({
    className,
    children,
    ...props
}) => {
    const { openInNewTab } = useOpenInNewTab()
    const handleClick = () => {
        const url = props.element.url
        openInNewTab(url)
    }

    const value = props.element?.children?.[0]?.text
    if (!value) return null

    const formattedTimestamp = formatYoutubeTimestamp(value)

    return (
        <PlateElement asChild {...props}>
            <Box
                component={"span"}
                onClick={handleClick}
                contentEditable={false}
                sx={{
                    gap: 0.5,
                    cursor: "pointer",
                    color: (theme) => alpha(theme.palette.text.primary, 0.5),
                    fontStyle: "italic",
                    textDecoration: "none",
                    "&:hover": {
                        textDecoration: "underline",
                        color: "#3366ccee",
                    },
                }}
            >
                {children}
                {formattedTimestamp}
            </Box>
        </PlateElement>
    )
}
